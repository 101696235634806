body {
  color: #252525;
}

.site-btn {
  background: #3333ff;
}
.site-btn.gray-btn {
  color: #252525;
}
.site-btn.gray-btn:hover, .site-btn.gray-btn.hover {
  -webkit-box-shadow: 10px 10px 20px rgba(37, 37, 37, 0.2);
          box-shadow: 10px 10px 20px rgba(37, 37, 37, 0.2);
  color: #252525;
}
.site-btn.gray-btn:active, .site-btn.gray-btn.active {
  background: #c7c7c7;
  -webkit-box-shadow: 10px 10px 20px rgba(37, 37, 37, 0.2);
          box-shadow: 10px 10px 20px rgba(37, 37, 37, 0.2);
}
.site-btn:hover, .site-btn.hover {
  -webkit-box-shadow: 10px 10px 20px rgba(51, 51, 255, 0.2);
          box-shadow: 10px 10px 20px rgba(51, 51, 255, 0.2);
}
.site-btn:active, .site-btn.active {
  background: #3333ff;
  -webkit-box-shadow: 10px 10px 20px rgba(51, 51, 255, 0.2);
          box-shadow: 10px 10px 20px rgba(51, 51, 255, 0.2);
}

.point:after {
  color: #3333ff;
}

.main-menu ul li a:hover {
  color: #3333ff;
}

.colored-link:hover, .colored-link:active, .colored-link.hover, .colored-link.active {
  color: #3333ff;
}

.mixitup-control-active {
  color: #3333ff;
}

a.fonts-icons:hover, a.fonts-icons.hover {
  color: #3333ff;
}
a.fonts-icons:active, a.fonts-icons.active {
  color: #3333ff;
}

.portfolio-box:hover .portfolio-name, .portfolio-box.hover .portfolio-name, .portfolio-box:active .portfolio-name, .portfolio-box.active .portfolio-name {
  color: #3333ff;
}

.form-wrapper .custom-select-box .custom-select-button:after {
  color: #3333ff;
}
.form-wrapper input[type=text]:hover + .form-validation, .form-wrapper input[type=text]:focus + .form-validation, .form-wrapper input[type=text]:active + .form-validation, .form-wrapper input[type=text].hover + .form-validation, .form-wrapper input[type=text].active + .form-validation,
.form-wrapper input[type=email]:hover + .form-validation,
.form-wrapper input[type=email]:focus + .form-validation,
.form-wrapper input[type=email]:active + .form-validation,
.form-wrapper input[type=email].hover + .form-validation,
.form-wrapper input[type=email].active + .form-validation {
  opacity: 1;
}
.form-wrapper textarea:hover + .form-validation, .form-wrapper textarea:focus + .form-validation {
  opacity: 1;
}

.mobile-btn {
  color: #3333ff;
}

.mob-menu-wrapper {
  background: #3333ff;
}

.wave-bg .zigzag svg:last-child polyline {
  stroke: #3333ff;
}

/*------------------------------------------------------------------
[Table of contents]

 - global styles
 - Loader
 - header
 - mobile-menu
 - sections
 - portfolio
 - form
 - modal


-------------------------------------------------------------------*/
a {
  color: inherit;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

a:hover,
a:active,
a:focus {
  color: inherit;
  outline: none !important;
  text-decoration: none;
}

ul, ol, h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

button:active,
button:focus {
  outline: none !important;
  text-decoration: none;
}

body {
  background: #fff;
  overflow-x: hidden;
}

img {
  border-radius: 2px;
}

:focus {
  outline: none !important;
}

/* Header */
.header {
  padding: 20px 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 1000;
  border-bottom: 1px solid #e3e3e3;
}
.header.active {
  -webkit-box-shadow: 0 5px 10px rgba(37, 37, 37, 0.1);
          box-shadow: 0 5px 10px rgba(37, 37, 37, 0.1);
  border-bottom-color: transparent;
}

.point:after {
  content: ".";
}

.main-menu {
  text-align: right;
}
.main-menu ul.list-inline {
  margin-right: -5px;
}
.main-menu ul li {
  margin: 8px 0 10px 20px;
}
.main-menu ul li a {
  display: inline-block;
}
.main-menu ul li a:hover {
  text-decoration: underline;
}

.colored-link {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}
.colored-link:hover, .colored-link:active, .colored-link.hover, .colored-link.active {
  text-decoration: underline;
}

/* Header */
/* Mobile-menu */
.mob-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  z-index: 9999;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  -webkit-transform: translateY(-100%);
      -ms-transform: translateY(-100%);
          transform: translateY(-100%);
  padding: 0 20px;
}
.mob-menu-wrapper.active {
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}

.mobile-menu {
  height: 100%;
  overflow: auto;
}
.mobile-menu ul {
  padding: 30px 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 100%;
}
.mobile-menu ul li {
  list-style: none;
  padding: 6px 0;
}
.mobile-menu ul li a {
  color: #fff;
  display: inline-block;
  padding: 11px;
}

.mobile-btn {
  padding: 3px 2px 2px;
  position: absolute;
  right: 15px;
  top: 3px;
  z-index: 5;
  font-size: 18px;
  vertical-align: middle;
}

.close-mob-menu {
  width: 20px;
  height: 20px;
  padding: 3px 2px 2px;
  position: absolute;
  top: 13px;
  right: 22px;
  z-index: 5;
  color: #ffffff;
  font-size: 18px;
}

/* Mobile-menu */
/* Sections */
.section {
  padding: 40px 0;
  border-top: 1px solid #e3e3e3;
}
.section.section-small-padding {
  padding: 40px 0 10px;
}

#hello {
  margin-top: 80px;
  border: none;
}

.about-img-wrap {
  float: right;
}
.about-img-wrap .about-img {
  position: relative;
  padding-bottom: 56.2%;
  margin-top: 20px;
}
.about-img-wrap .about-img img {
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  width: 50%;
  height: 100%;
}

.about-me-title {
  margin-bottom: 2px;
}

.span-title {
  float: left;
  display: block;
}
.span-title + span {
  display: block;
  padding-left: 90px;
  opacity: 0.8;
}
.span-title + .span-icons {
  opacity: 1;
}

.about-me-text {
  margin-bottom: 20px;
}

.opacity-box {
  opacity: 0.8;
}

.about-me-info {
  margin-bottom: 30px;
}
.about-me-info p {
  margin-bottom: 10px;
}
.about-me-info a.fonts-icons {
  padding-right: 20px;
  margin-bottom: 5px;
  min-width: 16px;
  text-align: center;
}

.site-btn {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  min-width: 170px;
  text-align: center;
  padding: 15px;
  display: inline-block;
  border: none;
  border-radius: 2px;
}
.site-btn.gray-btn {
  background: #efefef;
}
.site-btn:hover, .site-btn:active, .site-btn:focus {
  color: #fff;
}

.about-btns .site-btn {
  margin-right: 30px;
}

.advantages-box {
  margin-bottom: 0px;
}
.advantages-box h4 {
  margin-bottom: 2px;
}

.wave-bg {
  position: relative;
}
.wave-bg .zigzag {
  width: 105px;
  position: absolute;
  bottom: -5px;
  left: 15px;
}
.wave-bg .zigzag svg:first-child polyline {
  stroke: #efefef;
}

.section-small-padding .wave-bg .zigzag {
  bottom: 45px;
}
.section-small-padding .section-sidebar {
  padding-bottom: 75px;
}

.about-row .about-tittle {
  float: left;
  margin-right: 10px;
}
.about-row .about-info {
  padding: 2px 0;
  opacity: 0.8;
}
.about-row .about-info + p {
  opacity: 0.8;
}
.about-row .opacity-box {
  margin-top: 10px;
  font-style: italic;
}

.feedback-block .about-tittle {
  float: left;
  margin-right: 10px;
  padding-left: 70px;
}
.feedback-block .about-info {
  padding: 2px 0;
  padding-left: 70px;
  opacity: 0.8;
}
.feedback-block .feedback-image {
  left: 15px;
  height: 50px;
  width: 50px;
  position: absolute;
  border-radius: 3px;
}
.feedback-block .opacity-box {
  margin-top: 10px;
  font-style: italic;
  padding-left: 70px;
}

.right-box > .row:last-child .about-row, .right-box > .row:last-child .about-date {
  margin-bottom: 0;
}

.section-sidebar {
  padding-bottom: 50px;
}
.section-sidebar h2 {
  margin-bottom: 1px;
}

.section-title {
  margin-bottom: 20px;
}

/* Sections */
/* Portfolio */
.portfolio-sort {
  margin-bottom: 20px;
  white-space: nowrap;
  position: relative;
}
.portfolio-sort:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(93%, rgb(255, 255, 255)), to(rgb(255, 255, 255)));
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 93%, rgb(255, 255, 255) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 93%, rgb(255, 255, 255) 100%);
}
.portfolio-sort ul {
  overflow: auto;
  font-size: 0;
}
.portfolio-sort ul li {
  margin: 0 25px 0 0;
  vertical-align: top;
}

.portfolio-box {
  display: block;
  margin: 0 auto 30px;
  text-align: left;
  position: relative;
  overflow: hidden;
  height: auto;
}
.portfolio-box .portfolio-img {
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 2px;
}
.portfolio-box .portfolio-img img {
  margin: 0;
  width: 100%;
  height: auto;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
}
.portfolio-box:hover .portfolio-img img {
  transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -moz-transform: scale(1.2);
}
.portfolio-box .portfolio-name {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 2px;
}
.portfolio-box .portfolio-date {
  opacity: 0.8;
}
.portfolio-box:hover .portfolio-name, .portfolio-box.hover .portfolio-name, .portfolio-box:active .portfolio-name, .portfolio-box.active .portfolio-name {
  text-decoration: underline;
}

/* Portfolio */
/* Form */
.form-wrapper .form-group {
  margin-bottom: 30px;
  position: relative;
}
.form-wrapper .form-group span {
  color: #ff4669;
  position: absolute;
  top: 14px;
  text-align: right;
  opacity: 0;
  right: 20px;
}
.form-wrapper .form-group .form-validation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 2px;
  border: 2px solid #3333ff;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.form-wrapper input[type=text],
.form-wrapper input[type=email] {
  height: 50px;
  width: 100%;
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  padding: 0 20px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.form-wrapper input[type=text]::-webkit-input-placeholder,
.form-wrapper input[type=email]::-webkit-input-placeholder {
  color: #082639;
  opacity: 0.5;
}
.form-wrapper input[type=text]::-moz-placeholder,
.form-wrapper input[type=email]::-moz-placeholder {
  color: #082639;
  opacity: 0.5;
}
.form-wrapper input[type=text]:-moz-placeholder,
.form-wrapper input[type=email]:-moz-placeholder {
  color: #082639;
  opacity: 0.5;
}
.form-wrapper input[type=text]:-ms-input-placeholder,
.form-wrapper input[type=email]:-ms-input-placeholder {
  color: #082639;
  opacity: 0.5;
}
.form-wrapper input[type=text][data-touched=true]:invalid + .form-validation,
.form-wrapper input[type=email][data-touched=true]:invalid + .form-validation {
  border-color: #ff4669;
  opacity: 1;
}
.form-wrapper input[type=text][data-touched=true]:invalid ~ .form-invalid-icon,
.form-wrapper input[type=email][data-touched=true]:invalid ~ .form-invalid-icon {
  opacity: 1;
}
.form-wrapper textarea {
  height: 130px;
  width: 100%;
  border: 1px solid #e3e3e3;
  padding: 15px 20px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  resize: none;
  display: block;
  border-radius: 2px;
}
.form-wrapper textarea::-webkit-input-placeholder {
  color: #082639;
  opacity: 0.5;
}
.form-wrapper textarea::-moz-placeholder {
  color: #082639;
  opacity: 0.5;
}
.form-wrapper textarea:-moz-placeholder {
  color: #082639;
  opacity: 0.5;
}
.form-wrapper textarea:-ms-input-placeholder {
  color: #082639;
  opacity: 0.5;
}
.form-wrapper textarea[data-touched=true]:invalid + .form-validation {
  border-color: #ff4669;
  opacity: 1;
}
.form-wrapper .submit-holder {
  text-align: right;
}
.form-wrapper select {
  display: none;
}
.form-wrapper .custom-select-box {
  width: 100%;
  border: 1px solid #e3e3e3;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  text-align: left;
}
.form-wrapper .custom-select-box:hover, .form-wrapper .custom-select-box.custom-select-box-opened {
  -webkit-box-shadow: 0px 10px 20px 0px rgba(37, 37, 37, 0.2);
          box-shadow: 0px 10px 20px 0px rgba(37, 37, 37, 0.2);
  border: 1px solid transparent;
}
.form-wrapper .custom-select-box .custom-select-button {
  height: 50px;
  line-height: 48px;
  padding: 0 40px 0 20px;
  position: relative;
  cursor: pointer;
  z-index: 11;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
}
.form-wrapper .custom-select-box .custom-select-button span {
  opacity: 0.5;
}
.form-wrapper .custom-select-box .custom-select-button:after {
  content: "\f107";
  font-family: "FontAwesome";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 25px;
}
.form-wrapper .custom-select-box .custom-select-ul {
  position: absolute;
  z-index: 15;
  left: -1px;
  right: -1px;
  top: 100%;
  background: #fff;
  border-top: 1px solid #e3e3e3;
  list-style: none;
  max-height: 200px;
  overflow: auto;
  padding: 0;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(37, 37, 37, 0.2);
          box-shadow: 0px 10px 20px 0px rgba(37, 37, 37, 0.2);
}
.form-wrapper .custom-select-box .custom-select-ul li {
  padding: 15px 30px 15px 20px;
  cursor: pointer;
  border-bottom: 1px solid #e3e3e3;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.form-wrapper .custom-select-box .custom-select-ul li:last-child {
  border-bottom: none;
}
.form-wrapper .custom-select-box .custom-select-ul li:hover {
  background: #efefef;
}

/* Form */
/* Modal */
.modal-body {
  text-align: left;
  white-space: normal;
}

.modal-content {
  border-radius: 0;
  border: none;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2);
          box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2);
}

#portfolio-modal .modal-body {
  display: none;
}
#portfolio-modal .about-me-info {
  margin-bottom: 20px;
}
#portfolio-modal .modal-images {
  text-align: center;
}
#portfolio-modal .modal-images img {
  max-width: 100%;
  margin-bottom: 20px;
}
#portfolio-modal .about-btns {
  margin-top: 10px;
}

.modal-body {
  padding: 40px;
}
.modal-body .close {
  position: absolute;
  top: 30px;
  right: 30px;
}
.modal-body .modal-title {
  margin-bottom: 2px;
}
.modal-body .modal-description {
  margin-bottom: 20px;
  opacity: 0.8;
}
.modal-body .modal-form .submit-holder {
  text-align: left;
}
.modal-body .modal-form .submit-holder .site-btn {
  margin-right: 30px;
}

/* Modal */
.st0 {
  fill: none;
}

.st1 {
  fill: none;
  stroke: #F95231;
  stroke-width: 7;
  stroke-miterlimit: 10;
}

svg.fa-magic,
svg.fa-times {
  width: 24px;
  height: 24px;
}

.style-page {
  padding-top: 120px;
}
.style-page h3 {
  margin-bottom: 45px;
}
.style-page h4 {
  margin-bottom: 35px;
}
.style-page > .row > .col-md-4 {
  margin-bottom: 70px;
}
.style-page .form-wrapper {
  max-width: 270px;
  width: 100%;
  display: inline-block;
}

.duotone-box {
  position: relative;
}
.duotone-box:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #3636fe;
  mix-blend-mode: screen;
  display: none;
  -webkit-transition: width 0.4s ease;
  -o-transition: width 0.4s ease;
  transition: width 0.4s ease;
}

.style-page-text .button-size {
  font-size: 14px;
  line-height: 1.45;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}
.style-page-text .mobile-version-text {
  font-size: 14px;
}
.style-page-text .mobile-version-text h1 {
  font-size: 36px;
}
.style-page-text .mobile-version-text h2 {
  font-size: 18px;
}

.footer {
  background: #efefef;
  padding: 15px 0 15px;
}

body {
  font-size: 16px;
  line-height: 1.56;
  font-family: "Roboto", sans-serif;
}

h1 {
  font-size: 36px;
  line-height: 1.25;
  font-weight: 700;
  font-family: "Lato", sans-serif;
}

h2 {
  font-size: 30px;
  line-height: 1.4;
  font-weight: 700;
  font-family: "Lato", sans-serif;
}

h3 {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 700;
  font-family: "Lato", sans-serif;
}

h4 {
  font-size: 18px;
  line-height: 1.55;
  font-weight: 700;
  font-family: "Lato", sans-serif;
}

.site-btn {
  font-size: 12px;
  line-height: 1.66;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Lato", sans-serif;
}

.logo {
  font-size: 24px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.main-menu ul li a {
  text-transform: uppercase;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  line-height: 1.66;
}

.colored-link {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  line-height: 1.4;
}

.about-me-info {
  font-size: 14px;
  line-height: 1.4;
}

.fonts-icons {
  font-size: 14px;
  line-height: 1.4;
}

.span-title {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  line-height: 1.66;
}

.portfolio-sort ul li {
  text-transform: uppercase;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  line-height: 1.66;
}

.portfolio-box .portfolio-name {
  font-weight: 700;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  line-height: 1.55;
}

.form-wrapper input[type=text],
.form-wrapper input[type=email] {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  line-height: 1.4;
}
.form-wrapper textarea {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  line-height: 1.4;
}
.form-wrapper .custom-select-box {
  text-transform: uppercase;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  line-height: 1.4;
}

.copyright {
  font-size: 14px;
  line-height: 1.4;
  opacity: 0.8;
}

.mobile-menu ul li a {
  font-size: 12px;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.66;
}

.modal-body .modal-title {
  line-height: 1.35;
  font-weight: 700;
  font-family: "Lato", sans-serif;
}

@media screen and (min-width: 992px) {
  .portfolio-box {
    max-width: 270px;
  }
  body.modal-open .header {
    padding-right: 17px;
  }
  .mobile-hr {
    display: none;
  }
}
@media screen and (max-width: 991px) {
  body {
    font-size: 14px;
    line-height: 1.4;
  }
  h2 {
    font-size: 24px;
    line-height: 1.5;
  }
  .logo {
    font-size: 18px;
    margin-top: 5px;
  }
  .about-img-wrap {
    float: none;
    text-align: center;
  }
  .about-img {
    margin-bottom: 20px;
  }
  h1, .modal-body .modal-title {
    font-size: 30px;
    line-height: 1.4;
  }
  .modal-body .modal-title {
    margin-bottom: 1px;
  }
  h4 {
    font-size: 18px;
    line-height: 1.55;
  }
  .wave-bg .zigzag {
    display: none;
  }
  .section-sidebar,
  .section-small-padding .section-sidebar {
    padding: 0;
    margin-bottom: 20px;
    margin-top: 0px;
  }
  .about-date {
    text-align: left;
  }
  .col-md-8 > .about-row {
    margin-bottom: 20px !important;
  }
  .header {
    padding: 10px 0 15px;
  }
  .container {
    padding: 0 20px;
  }
  .section {
    padding: 30px 0;
  }
  .section.section-small-padding {
    padding: 30px 0 5px;
  }
  .footer {
    padding: 15px 0 15px;
  }
  #hello {
    margin-top: 60px;
  }
  .about-me-text {
    padding-top: 5px;
  }
  .about-me-title {
    margin-bottom: 1px;
  }
  .section-sidebar h2 {
    margin-bottom: 2px;
    padding-bottom: 0px;
  }
  .about-me-info {
    margin-bottom: 10px;
  }
  .about-me-info a.mdi:before {
    margin-bottom: 10px;
  }
  .advantages-box h4,
  .about-row .color-h4 {
    margin-bottom: 4px;
  }
  .about-row .about-tittle {
    float: none;
    padding-bottom: 4px;
  }
  .about-row .about-info {
    float: left;
    padding: 0px;
    margin-right: 10px;
  }
  .feedback-block .about-tittle {
    float: none;
  }
  .feedback-block .opacity-box {
    padding-top: 5px;
    padding-left: 0px;
  }
  .portfolio-box {
    margin-bottom: 20px;
  }
  .portfolio-box .portfolio-name {
    margin-bottom: 4px;
  }
  .form-wrapper {
    padding-top: 10px;
  }
  .form-wrapper .form-group {
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 890px) {
  .modal-dialog {
    width: 850px;
  }
  .modal.fade .modal-center {
    -webkit-transform: translateY(-75%) !important;
        -ms-transform: translateY(-75%) !important;
            transform: translateY(-75%) !important;
    top: 50%;
    margin-top: 0;
    margin-bottom: 0;
  }
  .modal.in .modal-center {
    -webkit-transform: translateY(-50%) !important;
        -ms-transform: translateY(-50%) !important;
            transform: translateY(-50%) !important;
  }
}
@media screen and (max-width: 890px) {
  .modal {
    padding-left: 0px !important;
  }
  .modal-dialog {
    width: 100%;
    margin: 0;
  }
  .modal-body {
    padding: 40px 20px 30px;
  }
  .modal-body .close {
    top: 15px;
    right: 15px;
  }
  #portfolio-modal .about-btns {
    margin-top: 0px;
  }
}
@media (min-width: 768px) {
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
}
@media screen and (max-width: 480px) {
  .portfolio .mix {
    width: 100%;
  }
  .about-btns .site-btn {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }
  .about-btns .site-btn.gray-btn {
    margin-bottom: 0;
  }
  .submit-holder .site-btn {
    width: 100%;
  }
  .modal-body .modal-form .submit-holder .site-btn {
    margin: 0;
  }
  .modal-body .modal-form .submit-holder input.site-btn {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 992px) {
  .mobile-only {
   display: none;
  }
}
@media screen and (max-width: 991px) {
  .mobile-only {
    display: block;
   }
}

.feedback-icon {
  display: block;
  clear: both;
  margin-left: 70px;
}

.h1-main-title {
  font-size: 24px;
}

.portfolio-img img {
  width: 270px !important;
  height: 150px !important;
  object-fit: cover !important;
}

.experience-tools {
  font-style: italic;
}

.p-sub-title {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 100;
}
